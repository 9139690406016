<template lang="pug">
.flex-grow.flex.items-center
  sectionPrices.w-full
</template>

<script>
import { defineAsyncComponent } from "vue";

const sectionPrices = defineAsyncComponent(() =>
  import("@/components/www/prices/prices-page.vue")
);
export default {
  name: "Prices",
  components: {
    sectionPrices,
  },
};
</script>

<style lang="scss"></style>
